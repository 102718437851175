<template>
  <div class="box">
    <div class="headline">角色权限设置</div>
    <div class="content">
      <p>进入区域管理界面，【系统设置】→【角色权限设置】，如下图</p>
      <img src="../../assets/specificationImgs/img18.png" alt width="100%" />
      <p>初次登录系统时，角色和权限全是空的，需要管理员去新增。</p>
      <p>新增角色：</p>
      <img src="../../assets/specificationImgs/img19.png" alt width="100%" />
      <p>点击角色设置上方的“+”，触发新增角色的操作。补充角色信息，注意角色名称不能重复，点击确定即可。</p>
      <img src="../../assets/specificationImgs/img20.png" alt />
      <p>如需要修改，点击图中六边形图标：</p>
      <img src="../../assets/specificationImgs/img21.png" alt />
      <p>点击对应的菜单可以实现修改和删除操作。</p>
      <p>
        1）新增：先通过左侧角色列表点击《新增》输入《编号》、《名称》添加角色，再点击角色列表中的某一个角色，在右侧勾选相应的功能，点击“保存设置”即可完成角色权限设置。<br />
        2）修改：选中某一个角色，右侧则会显示该角色所拥有的权限功能信息，再按需勾选想要的功能，点击“保存设置”即可完成对角色权限信息的修改。
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
